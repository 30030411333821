import React from 'react'

import { Box, Skeleton } from '@mui/material'
import ForceGraph2D, { NodeObject, LinkObject } from 'react-force-graph-2d'

export type Node = NodeObject & {
  id: number
  label: string
  group: number
}

export type Link = LinkObject & {
  source: number
  target: number
}

export type NetworkPlotData = {
  nodes: Node[]
  links: Link[]
}

type Props = {
  isLoading: boolean
  data: NetworkPlotData | undefined
  onNodeClick: (node: Node) => void
}

const NetworkPlot: React.FC<Props> = (props) => {
  const drawNodeLabel = (node: Node, ctx: CanvasRenderingContext2D, globalScale: number) => {
    const labelThreshold = 1.15
    const labelThresholdGroup3 = 12
    let label = ''

    if (node.group === 1 || (node.group === 2 && globalScale > labelThreshold) || (node.group === 3 && globalScale > labelThresholdGroup3)) {
      label = node.label || ''
    }

    if (label && typeof node.x === 'number' && typeof node.y === 'number') {
      const fontSize = 12 / globalScale
      ctx.font = `${fontSize}px Sans-Serif`
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = '#000'
      ctx.fillText(label, node.x, node.y)
    }
  }

  return !props.isLoading ? (
    <ForceGraph2D
      width={Math.floor(window.innerWidth * 0.9)}
      height={Math.floor(window.innerHeight * 0.75)}
      graphData={props.data}
      nodeAutoColorBy='group'
      nodeLabel='label'
      linkDirectionalArrowLength={6}
      linkDirectionalArrowRelPos={1}
      onNodeClick={(node: Node) => props.onNodeClick(node)}
      nodeCanvasObjectMode={() => 'after'}
      nodeCanvasObject={(node: Node, ctx: CanvasRenderingContext2D, globalScale: number) => drawNodeLabel(node, ctx, globalScale)}
    />
  ) : (
    <Box m={3}>
      <Skeleton variant='rounded' height='calc(70vh)' />
    </Box>
  )
}

export default NetworkPlot
