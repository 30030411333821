import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import { iconsObj } from '../../icons/Icons'
import { IconButton, Typography } from '@mui/material'

type ListItemWithAvatarProps = {
  icon: React.ReactNode
  primaryLabel: string
  secondaryLabel: string
}

type Props = {
  id: number
  name: string
  description: string
  rootEfoDiseaseId: number
}

const secondaryTypographyStyle = { fontSize: '0.8rem' }

export const ListItemWithAvatar: React.FC<ListItemWithAvatarProps> = ({ icon, primaryLabel, secondaryLabel }) => (
  <ListItem>
    <ListItemAvatar>
      <Avatar>{icon}</Avatar>
    </ListItemAvatar>
    <ListItemText primary={primaryLabel} secondary={secondaryLabel ? secondaryLabel : 'Unknown'} secondaryTypographyProps={secondaryTypographyStyle} />
  </ListItem>
)

const TargetAndBaseDataButton: React.FC<Props> = (props) => {
  return (
    <Tooltip
      placement='right'
      title={
        <List>
          <ListItemWithAvatar icon={iconsObj.EMAIL} primaryLabel='Name' secondaryLabel={props.name} />
          <ListItemWithAvatar icon={iconsObj.ID} primaryLabel='ID' secondaryLabel={props.id.toString()} />
          <ListItemWithAvatar icon={iconsObj.INFO} primaryLabel='Description' secondaryLabel={props.description} />
        </List>
      }
    >
      <IconButton>
        <Typography variant='subtitle2' minWidth='25px' color='primary'>
          {props.id}
        </Typography>
      </IconButton>
    </Tooltip>
  )
}

export default TargetAndBaseDataButton
