import React from 'react'

import { Avatar, CardContent, CardHeader, IconButton, Card } from '@mui/material'
import { iconsObj } from '../icons/Icons'
import DroppableContainer from './DroppableContainer'
import DraggableItem from './DraggableItem'
import stringAvatar from '../scripts/utils'
import { Sample } from './TargetDataBase'

type Props = {
  id: string
  title: string
  samples: Sample[]
  onDelete: () => void
}

const SampleClassCard: React.FC<Props> = (props) => {
  return (
    <Card>
      <CardHeader
        avatar={<Avatar {...stringAvatar(props.title)} />}
        action={
          <IconButton sx={{ mt: 0.5 }} aria-label='delete-sample-class-card' onClick={() => props.onDelete()}>
            {iconsObj.DELETE}
          </IconButton>
        }
        title={props.title}
      />
      <CardContent sx={{ minHeight: '34vh', height: '34vh' }}>
        <DroppableContainer id={props.id}>
          {props.samples.map((sample) => (
            <DraggableItem key={sample.id} id={sample.id}>
              {sample.name}
            </DraggableItem>
          ))}
        </DroppableContainer>
      </CardContent>
    </Card>
  )
}

export default SampleClassCard
