import React, { useState } from 'react'

import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { iconsObj } from '../../icons/Icons'
import HorizontalLinearStepper from '../HorizontalLinearStepper'
import BaseDataGeneralInfoStep from '../new-base-data-stepper/BaseDataGeneralInfoStep'
import BaseDataSubDiseasesStep from '../new-base-data-stepper/BaseDataSubDiseasesStep'
import BaseDataPopulationStep, { ExtendedPopulation } from '../new-base-data-stepper/BaseDataPopulationStep'
import { SubmitHandler, useForm } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'
import BaseDataStudiesStep, { Study } from '../new-base-data-stepper/BaseDataStudiesStep'
import { useAuth } from '../../context/AuthContext'
import { FolderNode } from '../MultiSelectCheckboxTreeView'
import SimpleBackdrop from '../SimpleBackDrop'
import { Option } from '../forms/FormProps'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  reloadTable: number
  setReloadTable: (reloadTable: number) => void
}

export type RootEfoDiseaseOption = {
  id: number
  label: string
}

export type GeneralInfoFormInput = {
  name: string
  description: string
  rootEfoDisease: RootEfoDiseaseOption
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PopulationFormInput = Record<string, any>

type SelectedSubDisease = {
  db_id: number
  is_selected: boolean
}

export const getAllSubDiseasesWithSelection = (node: FolderNode, selectedDbIds: number[]): SelectedSubDisease[] => {
  const result: SelectedSubDisease[] = []

  const traverse = (node: FolderNode) => {
    if (node.db_id) {
      result.push({
        db_id: node.db_id,
        is_selected: selectedDbIds.includes(node.db_id),
      })
    }

    if (node.children) {
      node.children.forEach((child) => traverse(child))
    }
  }

  traverse(node)
  return result
}

const NewBaseDataDialog: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const [selectedRootEfoDiseaseId, setSelectedRootEfoDiseaseId] = useState<number>(0)
  const [efoDiseases, setEfoDiseases] = useState<Option[]>([])

  const [subDiseases, setSubDiseases] = useState<FolderNode>({} as FolderNode)
  const [selectedSubDiseaseDbIds, setSelectedSubDiseaseDbIds] = useState<number[]>([])
  const [selectedSubDiseaseIds, setSelectedSubDiseaseIds] = useState<number[]>([])
  const [hasSubDiseasesChanged, setHasSubDiseasesChanged] = useState<boolean>(false)

  const [population, setPopulation] = useState<ExtendedPopulation[]>([])
  const [hasPopulationChanged, setHasPopulationChanged] = useState<boolean>(false)

  const [studies, setStudies] = useState<Study[]>([])
  const [selectedStudiesIds, setSelectedStudiesIds] = useState<number[]>([])

  const [activeStep, setActiveStep] = useState<number>(0)

  const { backendRequest, logout } = useAuth()

  const {
    control: controlGeneralInfo,
    reset: resetGeneralInfo,
    handleSubmit: handleSubmitGeneralInfo,
    getValues: getGeneralInfoValues,
    watch: watchGeneralInfo,
    setValue: setValueGeneralInfo,
  } = useForm<GeneralInfoFormInput>()

  const selectedRootEfoDisease = watchGeneralInfo('rootEfoDisease')

  const {
    unregister: unregisterPopulation,
    control: controlPopulation,
    reset: resetPopulation,
    handleSubmit: handleSubmitPopulation,
  } = useForm<PopulationFormInput>()

  const handleClose = () => {
    props.setOpen(false)

    resetGeneralInfo()
    resetPopulation()

    setSelectedRootEfoDiseaseId(0)

    setSelectedSubDiseaseDbIds([])
    setSelectedSubDiseaseIds([])
    setSubDiseases({} as FolderNode)

    setPopulation([])

    setStudies([])
    setSelectedStudiesIds([])
    setActiveStep(0)
  }

  const createBaseData = async () => {
    setIsLoading(true)

    const { name, description, rootEfoDisease } = getGeneralInfoValues()

    const efoSubDiseases = getAllSubDiseasesWithSelection(subDiseases, selectedSubDiseaseDbIds)

    const getResponse = async () => {
      const response = await backendRequest({
        method: 'POST',
        endpoint: '/api/base-data-manager',
        requiresAuth: true,
        body: {
          name: name,
          description: description,
          root_efo_disease_id: rootEfoDisease.id,
          population: population.map((pop) => ({ id: pop.id, is_selected: pop.isSelected })),
          studies: studies.map((study) => ({ id: study.id, is_selected: selectedStudiesIds.includes(study.id) })),
          efo_diseases: efoSubDiseases.map((subDisease) => ({ id: subDisease.db_id, is_selected: subDisease.is_selected })),
        },
      })

      return { status: response.status, statusText: response.statusText, data: response.data }
    }
    getResponse()
      .then((response) => {
        if (response && response.status === 200) {
          props.setReloadTable(props.reloadTable + 1)
          enqueueSnackbar('Base data created successfully', { variant: 'success' })
          handleClose()
        } else if (response.status === 401) {
          logout()
        }
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
      .finally(() => setIsLoading(false))
  }

  const onGeneralInfoSubmit: SubmitHandler<GeneralInfoFormInput> = async (data) => {
    setSelectedRootEfoDiseaseId(data.rootEfoDisease.id)
    if (selectedRootEfoDiseaseId !== data.rootEfoDisease.id) {
      setSelectedSubDiseaseIds([])
      setSelectedSubDiseaseDbIds([])
    }
    setActiveStep(1)
  }

  const onSubDiseasesSubmit = () => {
    if (selectedSubDiseaseDbIds.length === 0) {
      enqueueSnackbar('Please select at least one sub-disease', { variant: 'error' })
      return
    }

    setActiveStep(2)
  }

  const onPopulationSubmit: SubmitHandler<PopulationFormInput> = async (data) => {
    const selectedPopulation: ExtendedPopulation[] = []

    // Find if at least one population is selected
    if (Object.values(data).every((isSelected) => !isSelected)) {
      enqueueSnackbar('Please select at least one population', { variant: 'error' })
      return
    }

    Object.entries(data).forEach(([populationName, isSelected]) => {
      const pop = population.find((pop) => pop.population === populationName)
      if (pop) {
        selectedPopulation.push({ id: pop.id, population: pop.population, isSelected: isSelected })
      }
    })

    // Find out if there are any changes in the selected population
    const hasPopulationChanged = selectedPopulation.some((pop) => pop.isSelected !== population.find((p) => p.id === pop.id)?.isSelected)
    setHasPopulationChanged(hasPopulationChanged)
    if (hasPopulationChanged) {
      setSelectedStudiesIds([])
    }

    setPopulation(selectedPopulation)
    setActiveStep(3)
  }

  const onStudiesSubmit = () => {
    if (selectedStudiesIds.length === 0) {
      enqueueSnackbar('Please select at least one study', { variant: 'error' })
      return
    }
    createBaseData()
  }

  const customHandleNext = () => {
    if (activeStep === 0) {
      handleSubmitGeneralInfo(onGeneralInfoSubmit)()
    } else if (activeStep === 1) {
      onSubDiseasesSubmit()
    } else if (activeStep === 2) {
      handleSubmitPopulation(onPopulationSubmit)()
    } else if (activeStep === 3) {
      onStudiesSubmit()
    }
  }

  const steps = [
    {
      title: 'Select General Information',
      id: 'select-general-information',
      component: (
        <BaseDataGeneralInfoStep
          control={controlGeneralInfo}
          efoDiseases={efoDiseases}
          setEfoDiseases={setEfoDiseases}
          initialRootEfoDisease={selectedRootEfoDisease}
          setValue={setValueGeneralInfo}
        />
      ),
    },
    {
      title: 'Select Sub-diseases',
      id: 'select-sub-diseases',
      component: (
        <BaseDataSubDiseasesStep
          subDiseases={subDiseases}
          setSubDiseases={setSubDiseases}
          selectedRootEfoDiseaseId={selectedRootEfoDiseaseId}
          selectedSubDiseaseIds={selectedSubDiseaseIds}
          setSelectedSubDiseaseIds={setSelectedSubDiseaseIds}
          selectedSubDiseaseDbIds={selectedSubDiseaseDbIds}
          setSelectedSubDiseaseDbIds={setSelectedSubDiseaseDbIds}
          setHasSubDiseasesChanged={setHasSubDiseasesChanged}
        />
      ),
    },
    {
      title: 'Select Population',
      id: 'select-population',
      component: (
        <BaseDataPopulationStep
          population={population}
          setPopulation={setPopulation}
          subDiseaseIds={selectedSubDiseaseIds}
          control={controlPopulation}
          reset={resetPopulation}
          hasSubDiseasesChanged={hasSubDiseasesChanged}
          setHasSubDiseasesChanged={setHasSubDiseasesChanged}
          setHasPopulationChanged={setHasPopulationChanged}
          unregister={unregisterPopulation}
        />
      ),
    },
    {
      title: 'Select Studies',
      id: 'select-studies',
      component: (
        <BaseDataStudiesStep
          studies={studies}
          setStudies={setStudies}
          selectedSubDiseaseIds={selectedSubDiseaseDbIds}
          selectedPopulationIds={population.filter((pop) => pop.isSelected).map((pop) => pop.id)}
          setSelectedStudiesIds={setSelectedStudiesIds}
          selectedStudiesIds={selectedStudiesIds}
          hasPopulationChanged={hasPopulationChanged}
          setHasPopulationChanged={setHasPopulationChanged}
        />
      ),
    },
  ]

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={false}
        scroll='paper'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='new-base-data-dialog'
        aria-describedby='new-base-data-dialog'
      >
        <DialogTitle>
          <Stack direction='row' spacing={1}>
            <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
              {iconsObj.BASE_DATA}
            </Paper>
            <Typography variant='h5'> New Base Data</Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {iconsObj.CLOSE}
        </IconButton>
        <DialogContent sx={{ height: '90vh', overflow: 'auto' }}>
          <HorizontalLinearStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} customHandleNext={customHandleNext} />
          {steps[activeStep].component}
        </DialogContent>
      </Dialog>
      <SimpleBackdrop isLoading={isLoading} />
    </React.Fragment>
  )
}

export default NewBaseDataDialog
