import React from 'react'
import { IconButtonProps } from '@mui/material/IconButton'

import { TooltipIconButton } from './ToooltipButton'

export type TableIconProps = {
  icon: React.ReactNode
  iconColor?: IconButtonProps['color']
  customIconColor?: string
  onClick?: () => void
  disabled?: boolean
  tooltipText?: string
  size?: 'medium' | 'small' | 'large'
  id?: string
}

const TableIconButton: React.FC<TableIconProps> = ({
  icon,
  iconColor = 'inherit',
  customIconColor = '',
  onClick,
  disabled = false,
  tooltipText,
  size = 'medium',
  id = '',
}) => {
  return (
    <TooltipIconButton color={iconColor} sx={{ color: customIconColor }} onClick={onClick} disabled={disabled} size={size} id={id} tooltipText={tooltipText}>
      {icon}
    </TooltipIconButton>
  )
}

export default TableIconButton
