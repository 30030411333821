import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormInputText from '../forms/FormInputText'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { SampleGroup } from '../TargetDataBase'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  setSampleGroupName: (sampleGroupName: string) => void
  sampleGroups: SampleGroup[]
}

type FormInput = {
  groupName: string
}

const AddSamplesGroupDialog: React.FC<Props> = (props) => {
  const { register, handleSubmit, reset, control } = useForm<FormInput>()

  const handleClose = () => {
    props.setOpen(false)
    reset()
  }

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    // Check if the group name already exists among sampleGroups
    const groupNameExists = props.sampleGroups.some((group) => group.name === data.groupName)

    if (groupNameExists) {
      enqueueSnackbar(`Group name '${data.groupName}' already exists. Please choose a different name.`, { variant: 'error' })
      return
    }

    props.setSampleGroupName(data.groupName)
    handleClose()
  }

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent>
          <Box m={1}>
            <FormInputText required label='Group Name' id='group-name' control={control} {...register('groupName')} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AddSamplesGroupDialog
