import React from 'react'

import { useDroppable } from '@dnd-kit/core'
import { Box } from '@mui/material'

type Props = {
  id: string
  children: React.ReactNode
}

export const DroppableContainer: React.FC<Props> = (props) => {
  const { setNodeRef } = useDroppable({ id: props.id })

  return (
    <Box
      ref={setNodeRef}
      sx={{
        width: '100%',
        minHeight: '100%',
        maxHeight: '100%',
        border: '1px dashed grey',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Box>
  )
}

export default DroppableContainer
