import React from 'react'

import { Typography } from '@mui/material'

const Home: React.FC = () => {
  return (
    <React.Fragment>
      <Typography variant='h4'>Home</Typography>
    </React.Fragment>
  )
}

export default Home
