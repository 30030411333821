import React, { useEffect, useMemo, useState } from 'react'
import TablePageSkeleton from '../components/TablePageSkeleton'
import PageHeading from '../components/PageHeading'
import { iconsObj } from '../icons/Icons'
import { Button, ButtonGroup } from '@mui/material'
import CustomDataGrid from '../components/CustomDataGrid'
import { DataGridProps, GridRenderCellParams } from '@mui/x-data-grid'
import { useAuth } from '../context/AuthContext'
import TableIconButton from '../components/buttons/TableIconButton'
import BackendRequestDialog from '../components/dialogs/BackendRequestDialog'
import { enqueueSnackbar } from 'notistack'
import { TargetOrBaseData } from './TargetDataManager'
import NewBaseDataDialog from '../components/dialogs/NewBaseDataDialog'
import BaseDataDialog from '../components/dialogs/BaseDataDialog'

type ExtendedBaseData = TargetOrBaseData & {
  actions: React.ReactNode
}

const BaseDataManager: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [extendedBaseData, setExtendedBaseData] = useState<ExtendedBaseData[]>([])

  const [selectedBaseDatatId, setSelectedBaseDatatId] = useState<number>(0)

  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false)

  const [showBaseDataDialog, setShowBaseDataDialog] = useState<boolean>(false)
  const [showAddBaseDataDialog, setShowAddBaseDataDialog] = useState<boolean>(false)

  const [reloadTable, setReloadTable] = useState<number>(0)

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    setIsLoading(true)

    const getData = async () => {
      const responseBaseData = await backendRequest({
        method: 'GET',
        endpoint: '/api/base-data-manager/all',
        requiresAuth: true,
      })

      const baseData = responseBaseData.data as TargetOrBaseData[] | undefined

      return { status: responseBaseData.status, data: baseData }
    }
    getData()
      .then((responseBaseData) => {
        if (responseBaseData.status === 200 && responseBaseData.data) {
          const extendedBaseData: ExtendedBaseData[] = responseBaseData.data.map((baseData: TargetOrBaseData) => {
            const actions = (
              <ButtonGroup>
                <TableIconButton
                  icon={iconsObj.OPEN}
                  size='small'
                  tooltipText='Open Base Data'
                  id='open_base_data_btn'
                  onClick={() => {
                    setSelectedBaseDatatId(baseData.id)
                    setShowBaseDataDialog(true)
                  }}
                />
                <TableIconButton
                  icon={iconsObj.DELETE}
                  size='small'
                  tooltipText='Delete Base Data'
                  id='delete_base_data_btn'
                  onClick={() => {
                    setSelectedBaseDatatId(baseData.id)
                    setShowRemoveDialog(true)
                  }}
                />
              </ButtonGroup>
            )
            return { ...baseData, actions }
          })

          setExtendedBaseData(extendedBaseData)
        } else if (responseBaseData.status === 401) {
          logout()
        }
        setIsLoading(false)
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
  }, [reloadTable])

  const columnSpec = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'name',
        headerName: 'Name',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'disease_trait',
        headerName: 'Disease / Trait',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => params.row.root_efo_disease.disease,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => params.row.actions,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TablePageSkeleton
        headerGridLeft={<PageHeading icon={iconsObj.BASE_DATA}>Base Data Manager</PageHeading>}
        headerGridRight={
          <Button variant='outlined' startIcon={iconsObj.ADD} onClick={() => setShowAddBaseDataDialog(true)}>
            Add Base Data
          </Button>
        }
        table={<CustomDataGrid rows={extendedBaseData} columns={columnSpec as DataGridProps['columns']} isLoading={isLoading} />}
      />
      <BackendRequestDialog
        show={showRemoveDialog}
        setShow={setShowRemoveDialog}
        handleSuccess={() => {
          setReloadTable((reloadTable) => reloadTable + 1)
        }}
        endpoint={`/api/base-data-manager/${selectedBaseDatatId}`}
        requestMethod='DELETE'
        title={'Are you sure you want to remove Base Data ' + selectedBaseDatatId + '?'}
        buttonText='Remove'
        buttonColor='error'
        buttonIcon={iconsObj.DELETE}
        id='remove-base-data-dialog'
      />
      <NewBaseDataDialog open={showAddBaseDataDialog} setOpen={setShowAddBaseDataDialog} reloadTable={reloadTable} setReloadTable={setReloadTable} />
      <BaseDataDialog
        open={showBaseDataDialog}
        setOpen={setShowBaseDataDialog}
        baseDataId={selectedBaseDatatId}
        reloadTable={reloadTable}
        setReloadTable={setReloadTable}
      />
    </React.Fragment>
  )
}

export default BaseDataManager
