import React from 'react'
import { Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'

import FormProps from './FormProps'

export type RadioOption = {
  value: string
  label: string
  id: string
}

type FormInputRadioGroupProps = FormProps &
  RadioGroupProps & {
    required?: boolean
    options: RadioOption[]
  }

const FormInputRadioGroup: React.FC<FormInputRadioGroupProps> = ({ name, control, label, required, options, ...props }) => {
  // define validation rules
  const ruleRequired = required ? { required: 'Check is required!' } : {}

  return (
    <FormControl>
      <FormLabel id={`radio-group-${name}`}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        rules={ruleRequired}
        defaultValue={props.defaultValue || ''}
        render={({ field: { onChange, value } }) => (
          <RadioGroup row {...props} onChange={onChange} value={value} defaultValue={props.defaultValue || ''}>
            {options.map((option: RadioOption, index: number) => (
              <FormControlLabel value={option.value} control={<Radio />} id={option.id} label={option.label} key={index} />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default FormInputRadioGroup
