import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import { enqueueSnackbar } from 'notistack'
import { Analysis } from './AnalysesManager'
import PrsResults from '../components/PrsResults'
import { TargetDataResponse } from '../components/dialogs/TargetDataDialog'
import { Grid } from '@mui/material'

type Props = {
  analysisId: string
}

const GeneralAnalysis: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [analysis, setAnalysis] = useState<Analysis>({} as Analysis)
  const [targetData, setTargetData] = useState<TargetDataResponse>({} as TargetDataResponse)

  // get params from router-dom and convert
  const analysisId = Number(useParams<Props>().analysisId)

  const { backendRequest, logout } = useAuth()

  const [snpIds, setSnpIds] = useState<number[]>([])

  useEffect(() => {
    const getData = async () => {
      const responseAnalysis = await backendRequest({
        method: 'GET',
        endpoint: `/api/analyses-manager/${analysisId}`,
        requiresAuth: true,
      })

      const analysisData = responseAnalysis.data as Analysis | undefined

      const responseSnps = await backendRequest({
        method: 'GET',
        endpoint: `/api/base-data-manager/${analysisData?.base_data_manager.id}/snps`,
        requiresAuth: true,
      })

      const snpsData = responseSnps.data as number[] | undefined

      const responseTargetData = await backendRequest({
        method: 'GET',
        endpoint: `/api/target-data-manager/assign-patients-and-classes/${analysisData?.target_data_manager.id}`,
        requiresAuth: true,
      })

      const targetData = responseTargetData.data as TargetDataResponse | undefined

      return {
        analysisStatus: responseAnalysis.status,
        analysisData: analysisData,
        snpsStatus: responseSnps.status,
        snpsData: snpsData,
        targetDataStatus: responseTargetData.status,
        targetData: targetData,
      }
    }
    getData()
      .then(({ analysisStatus, analysisData, snpsStatus, snpsData, targetDataStatus, targetData }) => {
        if (analysisStatus === 200 && analysisData) {
          setAnalysis(analysisData)
        } else if (analysisStatus === 401) {
          logout()
        }
        if (snpsStatus === 200 && snpsData) {
          setSnpIds(snpsData)
        } else if (snpsStatus === 401) {
          logout()
        }
        if (targetDataStatus === 200 && targetData) {
          setTargetData(targetData)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
  }, [])

  return (
    <Grid container spacing={2} pl={15} pr={8} mt={-4}>
      <PrsResults
        pageHeading='General Analysis'
        baseDataName={analysis.base_data_manager?.name}
        targetDataName={analysis.target_data_manager?.name}
        snpIds={snpIds}
        targetData={targetData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </Grid>
  )
}

export default GeneralAnalysis
