import React from 'react'

import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { Paper, Typography } from '@mui/material'

type Props = {
  id: number
  children: React.ReactNode
}

export const DraggableItem: React.FC<Props> = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id: props.id })

  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
  }

  return (
    <Paper ref={setNodeRef} elevation={3} style={{ margin: '8px', padding: '16px', ...style }} {...listeners} {...attributes}>
      <Typography variant='body2' noWrap>
        {props.children}
      </Typography>
    </Paper>
  )
}

export default DraggableItem
