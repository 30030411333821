import { Box, Skeleton } from '@mui/material'
import React from 'react'

import Plot from 'react-plotly.js'

const colors = [
  { lineColor: '#61a146', fillColor: '#d3eac8' },
  { lineColor: '#d5294d', fillColor: '#f7aab2' },
  { lineColor: '#f49d0c', fillColor: '#fde58a' },
  { lineColor: '#438e96', fillColor: '#bfe0e2' },
  { lineColor: '#d2643d', fillColor: '#eaba9f' },
  { lineColor: '#9173df', fillColor: '#e0dcf8' },
]

export type ViolinPlotData = {
  groupName: string
  y: number[]
  yText: string[]
}

type Props = {
  data: ViolinPlotData[]
  isLoading: boolean
}

const ViolinPlot: React.FC<Props> = (props) => {
  const groupsData = props.data.filter((group) => group.y.some((value) => value !== undefined))

  const data = groupsData.map((group, index) => {
    const filteredIndices = group.y.map((value, idx) => (value !== undefined ? idx : -1)).filter((idx) => idx !== -1)
    const filteredY = filteredIndices.map((idx) => group.y[idx])
    const filteredYText = filteredIndices.map((idx) => group.yText[idx])

    return {
      type: 'violin' as const,
      y: filteredY,
      box: {
        visible: true,
      },
      line: {
        color: colors[index % colors.length].lineColor,
      },
      meanline: {
        visible: true,
      },
      marker: {
        size: 3,
      },
      fillcolor: colors[index % colors.length].fillColor,
      points: 'all',
      opacity: 0.8,
      text: filteredYText,
      x0: group.groupName,
    }
  })

  return !props.isLoading ? (
    <Plot data={data} layout={{ paper_bgcolor: 'transparent', plot_bgcolor: 'transparent', showlegend: false }} style={{ width: '100%', height: '90%' }} />
  ) : (
    <Box width='100%' height='100%' p={3}>
      <Skeleton variant='rounded' height='90%' />
    </Box>
  )
}

export default ViolinPlot
