import React, { useEffect, useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'

import SubmitButton from '../buttons/SubmitButton'
import { iconsObj } from '../../icons/Icons'
import { useAuth } from '../../context/AuthContext'
import { EfoDisease } from '../../pages/AnalysesManager'
import TargetDataBase, { SampleGroup, TargetDataFormInput } from '../TargetDataBase'
import { AxiosError } from 'axios'

type Props = {
  targetDataId: number
  open: boolean
  setOpen: (open: boolean) => void
  reloadTable: number
  setReloadTable: (reloadTable: number) => void
}

type Patient = {
  id: number
  sample_name: string
}

type PatientClass = {
  id: number
  class_name: string
}

type PatientClassWithPatients = {
  patient_class: PatientClass
  patients: Patient[]
}

export type TargetDataResponse = {
  id: number
  name: string
  description: string | null
  root_efo_disease: EfoDisease
  patient_classes: PatientClassWithPatients[]
}

type InitialData = {
  sampleGroups: SampleGroup[]
  name: string
  description: string
  rootEfoDisease: string
}

const createInitialData = (patient_classes: PatientClassWithPatients[], name: string, description: string | null, rootEfoDisease: string) => {
  const initialDataValue: InitialData = {
    sampleGroups: patient_classes.map((patientClass) => ({
      name: patientClass.patient_class.class_name,
      samples: patientClass.patients.map((patient) => ({ id: patient.id, name: patient.sample_name })),
    })),
    name: name,
    description: description != null ? description : '',
    rootEfoDisease: rootEfoDisease,
  }
  return initialDataValue
}

const TargetDataDialog: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [sampleGroups, setSampleGroups] = useState<SampleGroup[]>([{ name: 'Unclassified', samples: [] }])

  const [rootEfoDisease, setRootEfoDisease] = useState<string>('')

  const [initialData, setInitialData] = useState<InitialData>({} as InitialData)
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false)

  const { register, handleSubmit, reset, control, setValue, watch } = useForm<TargetDataFormInput>()

  const { backendRequest, logout } = useAuth()

  const selectedRootEfoDisease = watch('rootEfoDisease')
  const selectedName = watch('name')
  const selectedDescription = watch('description')

  useEffect(() => {
    if (props.open === true) {
      const getData = async () => {
        const responseTargetData = await backendRequest({
          method: 'GET',
          endpoint: `/api/target-data-manager/assign-patients-and-classes/${props.targetDataId}`,
          requiresAuth: true,
        })

        const targetData = responseTargetData.data as TargetDataResponse | undefined

        return { status: responseTargetData.status, data: targetData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            // Sort samples by name
            response.data.patient_classes.forEach((patientClass) => {
              patientClass.patients = patientClass.patients.sort((a, b) => a.sample_name.localeCompare(b.sample_name))
            })

            setSampleGroups(
              response.data.patient_classes.map((patientClass) => ({
                name: patientClass.patient_class.class_name,
                samples: patientClass.patients.map((patient) => ({ id: patient.id, name: patient.sample_name })),
              }))
            )
            setValue('name', response.data.name)
            setValue('description', response.data.description != null ? response.data.description : '')
            setValue('rootEfoDisease', { id: response.data.root_efo_disease.id, label: response.data.root_efo_disease.disease })
            setRootEfoDisease(response.data.root_efo_disease.disease)

            // Set initial data
            const initialDataValue = createInitialData(
              response.data.patient_classes,
              response.data.name,
              response.data.description,
              response.data.root_efo_disease.disease
            )
            setInitialData(initialDataValue)
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
    }
  }, [props.open])

  // Check if data has changed
  useEffect(() => {
    const compareData = () => {
      const hasChanges =
        !!initialData &&
        (JSON.stringify(sampleGroups) !== JSON.stringify(initialData.sampleGroups) ||
          selectedRootEfoDisease?.label !== initialData.rootEfoDisease ||
          selectedName !== initialData.name ||
          selectedDescription !== initialData.description)

      setDataHasChanged(hasChanges)
    }
    compareData()
  }, [sampleGroups, selectedName, selectedRootEfoDisease, selectedDescription, initialData])

  const handleClose = () => {
    props.setOpen(false)
    setSampleGroups([{ name: 'Unclassified', samples: [] }])
    setRootEfoDisease('')
    reset()
  }

  const updateTargetData: SubmitHandler<TargetDataFormInput> = async () => {
    setIsLoading(true)

    // Check if at least one group insted of unclassified exists
    if (sampleGroups.length <= 1) {
      enqueueSnackbar('At least one group must be created.', { variant: 'error' })
      setIsLoading(false)
      return
    }

    let responseMessage = ''

    if (!!initialData && JSON.stringify(sampleGroups) !== JSON.stringify(initialData.sampleGroups)) {
      try {
        const response = await backendRequest({
          method: 'PUT',
          endpoint: `/api/target-data-manager/assign-patients-and-classes/${props.targetDataId}`,
          requiresAuth: true,
          body: {
            patient_classes: sampleGroups.map((group) => {
              return { patient_class_name: group.name, patient_ids: group.samples.map((sample) => sample.id) }
            }),
          },
        })

        if (response.status === 200) {
          setInitialData({ ...initialData, sampleGroups: JSON.parse(JSON.stringify(sampleGroups)) })
        }
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
      }
    }

    if (selectedName !== initialData?.name) {
      try {
        const response = await backendRequest({
          method: 'PUT',
          endpoint: `/api/target-data-manager/${props.targetDataId}/name`,
          requiresAuth: true,
          params: { name: selectedName },
        })

        if (response.status === 200) {
          setInitialData({ ...initialData, name: selectedName })
        }
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
      }
    }

    if (selectedRootEfoDisease.label !== initialData?.rootEfoDisease) {
      try {
        const response = await backendRequest({
          method: 'PUT',
          endpoint: `/api/target-data-manager/${props.targetDataId}/disease/${selectedRootEfoDisease.id}`,
          requiresAuth: true,
        })

        if (response.status === 200) {
          setInitialData({ ...initialData, rootEfoDisease: selectedRootEfoDisease.label })
        }
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
      }
    }

    if (selectedDescription !== initialData?.description) {
      try {
        const response = await backendRequest({
          method: 'PUT',
          endpoint: `/api/target-data-manager/${props.targetDataId}/description`,
          requiresAuth: true,
          params: { description: selectedDescription },
        })

        if (response.status === 200) {
          setInitialData({ ...initialData, description: selectedDescription })
        }
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
      }
    }

    if (responseMessage === '') {
      props.setReloadTable(props.reloadTable + 1)
      enqueueSnackbar(`Target data with ID ${props.targetDataId} was successfully saved.`, { variant: 'success' })
    } else {
      enqueueSnackbar(`An error occurred while saving the target data. ${responseMessage}`, { variant: 'error' })
    }

    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={false}
        open={props.open}
        onClose={handleClose}
        aria-labelledby='new-target-data-dialog'
        aria-describedby='new-target-data-dialog'
      >
        <DialogTitle>
          <Stack direction='row' spacing={1}>
            <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
              {iconsObj.TARGET_DATA}
            </Paper>
            <Typography variant='h5'> Target Data {props.targetDataId}</Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {iconsObj.CLOSE}
        </IconButton>
        <DialogContent sx={{ height: '80vh', overflow: 'hidden' }}>
          <TargetDataBase
            sampleGroups={sampleGroups}
            setSampleGroups={setSampleGroups}
            rootEfoDisease={rootEfoDisease}
            setRootEfoDisease={setRootEfoDisease}
            control={control}
            register={register}
          />
        </DialogContent>
        <DialogActions sx={{ mx: 2.5, mb: 2, mt: 3, justifyContent: 'center' }}>
          <SubmitButton
            id='save-target-data-btn'
            text='Save Changes'
            fullWidth
            disabled={!dataHasChanged}
            startIcon={iconsObj.SAVE}
            color='warning'
            onClick={handleSubmit(updateTargetData)}
            loading={isLoading}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default TargetDataDialog
