import React from 'react'

import { Autocomplete, Chip, TextField } from '@mui/material'
import { Option } from './FormProps'

type Props = {
  options: Option[]
  defaultValue: Option[]
  label: string
  onChange: (data: Option[]) => void
  fullWidth?: boolean
  optionColors?: Record<string, string>
}

const MultipleSelectCheckmarks: React.FC<Props> = ({ options, defaultValue = [], label, fullWidth, onChange, optionColors = null }) => {
  return (
    <Autocomplete
      multiple
      defaultValue={defaultValue}
      limitTags={3}
      onChange={(_, data) => onChange(data)}
      size='small'
      fullWidth={fullWidth}
      renderInput={(params) => <TextField {...params} label={label} />}
      id='multi-select-checkmarks'
      getOptionLabel={(option) => option.label}
      renderTags={(selected: Option[], getTagProps) =>
        selected
          .sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
          })
          .map((s, index: number) => (
            <Chip label={s.label} size='small' {...getTagProps({ index })} sx={{ backgroundColor: optionColors?.[s.value as string], fontSize: 11, m: 0.5 }} />
          ))
      }
      options={options}
      isOptionEqualToValue={(option: Option, value: Option) => option.id === value.id && option.label === value.label}
    />
  )
}

export default MultipleSelectCheckmarks
