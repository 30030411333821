import React from 'react'

import { DataGrid, DataGridProps, GridToolbar } from '@mui/x-data-grid'

type TableProps = DataGridProps & {
  rows: DataGridProps['rows']
  columns: DataGridProps['columns']
  getRowId?: DataGridProps['getRowId']
  noRowsText?: string
  isLoading?: boolean
  initialState?: DataGridProps['initialState']
  autoRowHeight?: boolean
  selectedRowIds?: number[]
}

export const CustomDataGrid: React.FC<TableProps> = (props) => {
  return (
    <DataGrid
      columns={props.columns}
      rows={props.rows}
      pagination
      disableRowSelectionOnClick
      getRowId={props.getRowId}
      density='standard'
      loading={props.isLoading}
      checkboxSelection={props.checkboxSelection}
      onRowSelectionModelChange={props.onRowSelectionModelChange}
      rowSelectionModel={props.rowSelectionModel}
      getRowHeight={() => (props.autoRowHeight ? 'auto' : undefined)}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      initialState={
        props.initialState
          ? props.initialState
          : {
              sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
            }
      }
      sx={{
        '--DataGrid-overlayHeight': '300px',
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '18px' },
      }}
    />
  )
}

export default CustomDataGrid
