import React from 'react'
import Stack from '@mui/material/Stack'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { Box } from '@mui/material'

type Props = TypographyProps & {
  icon: React.ReactElement
}

const PageHeading: React.FC<Props> = ({ icon, children, variant = 'h4', ...props }) => {
  return (
    <Typography variant={variant}>
      <Stack direction='row' display='flex' alignItems='start' spacing={1}>
        <Box sx={{ '& svg': { fontSize: 40 } }}>{icon}</Box>
        {typeof children === 'string' ? (
          <Typography {...props} variant='inherit'>
            {children}
          </Typography>
        ) : (
          children
        )}
      </Stack>
    </Typography>
  )
}

export default PageHeading
