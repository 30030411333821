import { Box, Grid, Stack } from '@mui/material'
import React from 'react'

type Props = {
  headerGridLeft?: React.ReactNode
  headerGridRight?: React.ReactNode
  table: React.ReactNode
}

const TablePageSkeleton: React.FC<Props> = (props) => {
  return (
    <Stack spacing={2} mt={-4} flexDirection='column' paddingRight={8} paddingLeft={15} overflow='hidden'>
      <Grid container>
        <Grid item md={6} xs={12}>
          {props.headerGridLeft}
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>{props.headerGridRight}</Box>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='center' alignItems='stretch'>
        <Grid item flex={1} mt={4} sx={{ minHeight: 'calc(80vh)' }}>
          {props.table}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default TablePageSkeleton
