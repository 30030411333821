import React, { useEffect, useMemo, useState } from 'react'
import NewBaseDataSkeleton from '../NewBaseDataSkeleton'
import { useAuth } from '../../context/AuthContext'
import { enqueueSnackbar } from 'notistack'
import {} from '../dialogs/NewBaseDataDialog'
import CustomDataGrid from '../CustomDataGrid'
import { DataGridProps, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import RedirectToUrlButton from '../buttons/RedirectToUrlButton'

type Props = {
  studies: Study[]
  setStudies: (studies: Study[]) => void
  selectedSubDiseaseIds: number[]
  selectedPopulationIds: number[]
  selectedStudiesIds: number[]
  setSelectedStudiesIds: (selectedStudies: number[]) => void
  hasPopulationChanged: boolean
  setHasPopulationChanged: (hasPopulationChanged: boolean) => void
}

export type Study = {
  id: number
  study: string
  pubmed_id: number
  link: string
  journal: string
  date: string
  first_author: string
  initial_sample_size: string
  replication_sample_size: string
}

const BaseDataStudiesStep: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    if (props.hasPopulationChanged) {
      setIsLoading(true)
      const getData = async () => {
        const responseStudies = await backendRequest({
          method: 'POST',
          endpoint: '/api/gwas-variants/filter/studies',
          requiresAuth: true,
          body: { sub_disease_ids: props.selectedSubDiseaseIds, gwas_population_ids: props.selectedPopulationIds },
        })

        const studiesData = responseStudies.data as Study[] | undefined

        return { status: responseStudies.status, data: studiesData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            props.setStudies(response.data)
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
        .finally(() => {
          setIsLoading(false)
          props.setHasPopulationChanged(false)
        })
    }
  }, [])

  const onRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel) => {
    props.setSelectedStudiesIds(rowSelectionModel as number[])
  }

  const columnSpec = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        editable: false,
        hide: true,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'study',
        headerName: 'Study',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        width: 500,
      },
      {
        field: 'date',
        headerName: 'Date',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'journal',
        headerName: 'Journal',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'pubmed_id',
        headerName: 'PubMed ID',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'link',
        headerName: 'Link',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params: GridRenderCellParams) => <RedirectToUrlButton url={params.row.link} />,
      },
      {
        field: 'first_author',
        headerName: 'First Author',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'initial_sample_size',
        headerName: 'Initial Sample Size',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        width: 350,
      },
      {
        field: 'replication_sample_size',
        headerName: 'Replication Sample Size',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        width: 350,
      },
    ],
    []
  )

  return (
    <NewBaseDataSkeleton
      title='Choose Studies'
      children={
        <Box sx={{ minHeight: 'calc(62vh)', maxHeight: 'calc(62vh)', height: 'calc(62vh)', flexDirection: 'column' }}>
          <CustomDataGrid
            rows={props.studies}
            columns={columnSpec as DataGridProps['columns']}
            isLoading={isLoading}
            checkboxSelection={true}
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={props.selectedStudiesIds}
            autoRowHeight={true}
          />
        </Box>
      }
    />
  )
}

export default BaseDataStudiesStep
