import {
  Login,
  Logout,
  ExpandMore,
  Error,
  CheckCircle,
  Close,
  Warning,
  BarChart,
  Home,
  AccountCircle,
  Menu,
  ChevronLeft,
  ChevronRight,
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
  Add,
  Edit,
  DeleteOutline,
  OpenInNew,
  Grid3x3,
  InfoOutlined,
  AlternateEmail,
  Groups,
  MoreVert,
  Save,
  TuneOutlined,
  Settings,
} from '@mui/icons-material'

export const iconsObj = {
  ERROR: <Error fontSize='medium' color='error' />,
  SUCCESS: <CheckCircle fontSize='medium' color='success' />,
  WARNING: <Warning fontSize='inherit' color='warning' />,
  LOGIN: <Login fontSize='medium' />,
  LOGOUT: <Logout fontSize='inherit' />,
  EXPAND_MORE: <ExpandMore fontSize='large' />,
  CLOSE: <Close fontSize='inherit' />,
  HOME: <Home fontSize='medium' />,
  ANALYSES: <BarChart fontSize='medium' />,
  ACCOUNT: <AccountCircle fontSize='medium' />,
  MENU: <Menu fontSize='medium' />,
  LEFT: <ChevronLeft fontSize='medium' />,
  RIGHT: <ChevronRight fontSize='medium' />,
  SUBMIT: <CheckCircleOutline fontSize='inherit' />,
  VISIBILITY: <Visibility fontSize='inherit' />,
  VISIBILITYOFF: <VisibilityOff fontSize='inherit' />,
  ADD: <Add fontSize='inherit' />,
  MODIFY: <Edit fontSize='inherit' />,
  DELETE: <DeleteOutline fontSize='inherit' />,
  OPEN: <OpenInNew fontSize='inherit' />,
  ID: <Grid3x3 fontSize='inherit' />,
  INFO: <InfoOutlined fontSize='inherit' />,
  EMAIL: <AlternateEmail fontSize='inherit' />,
  TARGET_DATA: <Groups fontSize='medium' />,
  BASE_DATA: <TuneOutlined fontSize='medium' />,
  MORE: <MoreVert fontSize='inherit' />,
  SAVE: <Save fontSize='inherit' />,
  SETTINGS: <Settings fontSize='inherit' />,
}
