import React, { useState, useEffect, useMemo } from 'react'

import { Button, ButtonGroup, Chip } from '@mui/material'
import { DataGridProps, GridRenderCellParams } from '@mui/x-data-grid'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import { iconsObj } from '../icons/Icons'
import PageHeading from '../components/PageHeading'
import CustomDataGrid from '../components/CustomDataGrid'
import { useAuth } from '../context/AuthContext'
import TableIconButton from '../components/buttons/TableIconButton'
import BackendRequestDialog from '../components/dialogs/BackendRequestDialog'
import TargetAndBaseDataButton from '../components/buttons/TargetAndBaseDataButton'
import NewAnalysisDialog from '../components/dialogs/NewAnalysisDialog'
import TablePageSkeleton from '../components/TablePageSkeleton'
import { AnalysisType } from '../enums/AnalysisManager'

export type EfoDisease = {
  id: number
  disease: string
  ontology_id: string
}

type PValueThreshold = {
  id: number
  p_value: number
}

type HighlightedPatient = {
  id: number
  sample_name: string
}

type BaseAndTargetData = {
  id: number
  name: string
  description: string
  root_efo_disease_id: number
}

export type Analysis = {
  id: number
  name: string
  analysis_type: AnalysisType
  base_data_manager: BaseAndTargetData
  target_data_manager: BaseAndTargetData
  root_efo_disease: EfoDisease
  p_value_threshold: PValueThreshold | null
  highlighted_patient: HighlightedPatient | null
}

type ExtendedAnalyses = Analysis & {
  actions: React.ReactNode
  targetData: React.ReactNode
}

const analysisTypeChip: { [key in AnalysisType]: { color: string; label: string } } = {
  [AnalysisType.general]: { color: '#0891b2', label: 'General' },
  [AnalysisType.pathway_based]: { color: '#0d9488', label: 'Pathway Based' },
  [AnalysisType.process_based]: { color: '#059669', label: 'Process Based' },
}

const AnalysesManager: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [analyses, setAnalyses] = useState<ExtendedAnalyses[]>([])

  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false)
  const [showAddAnalysisDialog, setShowAddAnalysisDialog] = useState<boolean>(false)

  const [selectedAnalyisId, setSelectedAnalyisId] = useState<number>(0)

  const [reloadTable, setReloadTable] = useState<number>(0)

  const { backendRequest, logout } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)

    const getData = async () => {
      const responseAnalyses = await backendRequest({
        method: 'GET',
        endpoint: '/api/analyses-manager',
        requiresAuth: true,
      })

      const analysesData = responseAnalyses.data as Analysis[] | undefined

      return { status: responseAnalyses.status, data: analysesData }
    }
    getData()
      .then((responseAnalyses) => {
        if (responseAnalyses.status === 200 && responseAnalyses.data) {
          const extendedAnalyses: ExtendedAnalyses[] = responseAnalyses.data.map((analysis: Analysis) => {
            const targetData = (
              <TargetAndBaseDataButton
                id={analysis.target_data_manager.id}
                name={analysis.target_data_manager.name}
                description={analysis.target_data_manager.description}
                rootEfoDiseaseId={analysis.target_data_manager.root_efo_disease_id}
              />
            )
            const baseData = (
              <TargetAndBaseDataButton
                id={analysis.base_data_manager.id}
                name={analysis.base_data_manager.name}
                description={analysis.base_data_manager.description}
                rootEfoDiseaseId={analysis.base_data_manager.root_efo_disease_id}
              />
            )
            const actions = (
              <ButtonGroup>
                <TableIconButton
                  icon={iconsObj.OPEN}
                  size='small'
                  tooltipText='Open Analysis'
                  id='open_analysis_btn'
                  onClick={() => {
                    navigate(`/analysis/${analysis.analysis_type}/` + analysis.id)
                  }}
                />
                <TableIconButton icon={iconsObj.MODIFY} size='small' tooltipText='Open Analysis' id='modify_analysis_btn' />
                <TableIconButton
                  icon={iconsObj.DELETE}
                  size='small'
                  tooltipText='Delete Analysis'
                  id='delete_analysis_btn'
                  onClick={() => {
                    setSelectedAnalyisId(analysis.id)
                    setShowRemoveDialog(true)
                  }}
                />
              </ButtonGroup>
            )
            return { ...analysis, actions, targetData, baseData }
          })

          setAnalyses(extendedAnalyses)
        } else if (responseAnalyses.status === 401) {
          logout()
        }
        setIsLoading(false)
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
  }, [reloadTable])

  const columnSpec = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'name',
        headerName: 'Name',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'disease_trait',
        headerName: 'Disease / Trait',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => params.row.root_efo_disease.disease,
      },
      {
        field: 'analysis_type',
        headerName: 'Type',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Chip
            size='small'
            label={analysisTypeChip[params.row.analysis_type as AnalysisType].label}
            sx={{ backgroundColor: analysisTypeChip[params.row.analysis_type as AnalysisType].color, color: 'white' }}
          />
        ),
      },
      {
        field: 'target_data',
        headerName: 'Target Data',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.targetData,
      },
      {
        field: 'base_data',
        headerName: 'Base Data',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.baseData,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => params.row.actions,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TablePageSkeleton
        headerGridLeft={<PageHeading icon={iconsObj.ANALYSES}>Analyses Manager</PageHeading>}
        headerGridRight={
          <Button variant='outlined' startIcon={iconsObj.ADD} id='add-analysis-btn' onClick={() => setShowAddAnalysisDialog(true)}>
            Add Analysis
          </Button>
        }
        table={<CustomDataGrid rows={analyses} columns={columnSpec as DataGridProps['columns']} isLoading={isLoading} />}
      />
      <BackendRequestDialog
        show={showRemoveDialog}
        setShow={setShowRemoveDialog}
        handleSuccess={() => {
          setReloadTable((reloadTable) => reloadTable + 1)
        }}
        endpoint={`/api/analyses-manager/${selectedAnalyisId}`}
        requestMethod='DELETE'
        title={'Are you sure you want to remove Analysis ' + selectedAnalyisId + '?'}
        buttonText='Remove'
        buttonColor='error'
        buttonIcon={iconsObj.DELETE}
        id='remove-analysis-dialog'
      />
      <NewAnalysisDialog open={showAddAnalysisDialog} setOpen={setShowAddAnalysisDialog} reloadTable={reloadTable} setReloadTable={setReloadTable} />
    </React.Fragment>
  )
}

export default AnalysesManager
