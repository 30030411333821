import React from 'react'
import { Controller } from 'react-hook-form'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import FormProps from './FormProps'

type FormInputChekboxProps = FormProps & CheckboxProps

const FormInputCheckbox: React.FC<FormInputChekboxProps> = ({ name, control, label, required, ...props }) => {
  const ruleRequired = required ? { required: 'Check is required!' } : {}

  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          rules={ruleRequired}
          render={({ field: { onChange, value } }) => (
            <Checkbox sx={{ p: 0, mr: 1 }} {...props} onChange={onChange} checked={Boolean(value)} value={value} required={required} />
          )}
        />
      }
    />
  )
}

export default FormInputCheckbox
