import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'

import { iconsObj } from '../../icons/Icons'
import { useAuth } from '../../context/AuthContext'
import SubmitButton from '../buttons/SubmitButton'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  onSuccessLogin?: () => void
}

type FormInput = {
  email: string
  password: string
}

const defaultValues: FormInput = {
  email: '',
  password: '',
}

const LoginDialog: React.FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { register, handleSubmit, reset } = useForm<FormInput>({ defaultValues: defaultValues })

  const { login } = useAuth()

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    setLoading(true)
    const message = await login(data.email, data.password)

    try {
      if (message.length > 0) {
        enqueueSnackbar(message, { variant: 'error' })
      } else {
        handleClose()
        if (props.onSuccessLogin !== undefined) {
          props.onSuccessLogin()
        }
      }
    } catch {
      enqueueSnackbar('Faild to log in', { variant: 'error' })
    }
    setLoading(false)
  }

  const handleClose = () => {
    props.setOpen(false)
    reset()
  }

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby='login-dialog' aria-describedby='login-dialog'>
      <DialogTitle>
        <Stack direction='row' spacing={1}>
          <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
            {iconsObj.LOGIN}
          </Paper>
          <Typography variant='h5'> Log In</Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent>
        <Grid container>
          <Grid item lg={12}>
            <Grid container direction='column' justifyContent='center' alignItems='stretch'>
              <TextField required id='login-email' label='Email' sx={{ m: 2 }} {...register('email')} />
              <FormControl required variant='outlined' sx={{ m: 2 }}>
                <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
                <OutlinedInput
                  {...register('password')}
                  id='login-password'
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                        {showPassword ? iconsObj.VISIBILITYOFF : iconsObj.VISIBILITY}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                />
              </FormControl>
              <Grid item mx={2} mt={3}>
                <SubmitButton id='dialog-login' text='Log In' fullWidth onClick={handleSubmit(onSubmit)} loading={loading} startIcon={iconsObj.LOGIN} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mx: 3, mb: 2, justifyContent: 'center' }}>
        <Link underline='hover'>
          <Typography variant='caption'>Forgot password?</Typography>
        </Link>
        <Link underline='hover'>
          <Typography variant='caption'>Don't have an account? Sign Up</Typography>
        </Link>
      </DialogActions>
    </Dialog>
  )
}
export default LoginDialog
