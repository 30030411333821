import React, { useEffect, useState } from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { Control, UseFormRegister } from 'react-hook-form'
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core'

import FormInputText from './forms/FormInputText'
import FormInputComboBox from './forms/FormInputComboBox'
import DroppableContainer from './DroppableContainer'
import DraggableItem from './DraggableItem'
import SampleClassCard from './SampleClassCard'
import AddSamplesGroupDialog from './dialogs/AddSamplesGroupDialog'
import { Option } from './forms/FormProps'
import { useAuth } from '../context/AuthContext'
import { EfoDisease } from '../pages/AnalysesManager'
import { iconsObj } from '../icons/Icons'
import { enqueueSnackbar } from 'notistack'

export type Sample = {
  id: number
  name: string
}

export type SampleGroup = {
  name: string
  samples: Sample[]
}

export type TargetDataFormInput = {
  name: string
  rootEfoDisease: { id: number; label: string }
  description: string
}

type Props = {
  sampleGroups: SampleGroup[]
  setSampleGroups: (sampleGroups: SampleGroup[]) => void
  rootEfoDisease: string
  setRootEfoDisease: (rootEfoDisease: string) => void
  register: UseFormRegister<TargetDataFormInput>
  control: Control<TargetDataFormInput>
}

export const sortSamplesByName = (samples: Sample[]) => {
  return samples.sort((a, b) => a.name.localeCompare(b.name))
}

const TargetDataBase: React.FC<Props> = (props) => {
  const [efoDiseases, setEfoDiseases] = useState<Option[]>([])

  const [activeId, setActiveId] = useState<number | null>(null)

  const [openAddSamplesGroupDialog, setOpenAddSamplesGroupDialog] = useState<boolean>(false)
  const [sampleGroupName, setSampleGroupName] = useState<string>('')

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    const getData = async () => {
      const responseEfoDiseases = await backendRequest({
        method: 'GET',
        endpoint: `/api/disease/full-text-search?disease=${props.rootEfoDisease}`,
        requiresAuth: true,
      })

      const efoDiseasesData = responseEfoDiseases.data as EfoDisease[] | undefined

      return { status: responseEfoDiseases.status, data: efoDiseasesData }
    }
    getData()
      .then((response) => {
        if (response.status === 200 && response.data) {
          setEfoDiseases(response.data.map((efoDisease) => ({ id: efoDisease.id, label: efoDisease.disease })))
        } else if (response.status === 401) {
          logout()
        }
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
  }, [props.rootEfoDisease])

  useEffect(() => {
    if (sampleGroupName) {
      const newSampleGroups = [...props.sampleGroups]
      newSampleGroups.push({ name: sampleGroupName, samples: [] })
      props.setSampleGroups(newSampleGroups)
      setSampleGroupName('')
    }
  }, [sampleGroupName])

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const newSampleGroups = [...props.sampleGroups]
      let sourceGroupIndex = null
      let sourceSampleIndex = null
      let targetGroupIndex = null

      for (const [groupIndex, group] of newSampleGroups.entries()) {
        const index = group.samples.findIndex((sample) => sample.id === active.id)
        if (index !== -1) {
          sourceGroupIndex = groupIndex
          sourceSampleIndex = index
          break
        }
      }

      targetGroupIndex = newSampleGroups.findIndex((group) => group.name === over.id)

      if (sourceGroupIndex !== null && sourceSampleIndex !== null && targetGroupIndex !== null) {
        // Move the sample to the new group
        const [removedSample] = newSampleGroups[sourceGroupIndex].samples.splice(sourceSampleIndex, 1)
        newSampleGroups[targetGroupIndex].samples.push(removedSample)

        // Sort the target group's samples
        newSampleGroups[targetGroupIndex].samples = sortSamplesByName(newSampleGroups[targetGroupIndex].samples)

        props.setSampleGroups(newSampleGroups)
      }
    }
  }

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    if (active) {
      setActiveId(active.id as number)
    }
  }

  const handleMoveAllSamplesToUnclassified = (groupName: string) => {
    const newSampleGroups = [...props.sampleGroups]
    const groupIndex = newSampleGroups.findIndex((group) => group.name === groupName)
    if (groupIndex > -1) {
      const unclassifiedGroupIndex = newSampleGroups.findIndex((group) => group.name === 'Unclassified')
      if (unclassifiedGroupIndex > -1) {
        newSampleGroups[unclassifiedGroupIndex].samples = newSampleGroups[unclassifiedGroupIndex].samples.concat(newSampleGroups[groupIndex].samples)
        newSampleGroups[groupIndex].samples = []

        // Delete the group if it is not the unclassified group
        if (groupName !== 'Unclassified') {
          newSampleGroups.splice(groupIndex, 1)
        }

        props.setSampleGroups(newSampleGroups)
      }
    }
  }

  const findSampleById = (sampleId: number) => {
    // Loop through each sampleGroup
    for (const group of props.sampleGroups) {
      // Attempt to find the sample in the current group
      const sample = group.samples.find((sample) => sample.id === sampleId)
      // If found, return the sample
      if (sample) {
        return sample.name
      }
    }
    // If no sample is found in any group, return undefined
    return undefined
  }

  return (
    <React.Fragment>
      <Grid container direction='row' spacing={2} mb={4} px={0.5}>
        <Grid item md={3}>
          <FormInputText required id='new-target-data-name' label='Name' name='name' control={props.control} />
        </Grid>
        <Grid item md={3}>
          <FormInputComboBox
            required
            id='new-target-data-root-efo-disease'
            label='Root EFO Disease'
            name='rootEfoDisease'
            options={efoDiseases}
            control={props.control}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                props.setRootEfoDisease(value)
              }
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormInputText id='new-target-data-description' fullWidth label='Description' name='description' control={props.control} />
        </Grid>
      </Grid>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Typography variant='body1' align='left' ml={0.5}>
          Unclassified Samples
        </Typography>
        <Box height='17vh' mt={1.5} mb={0.5} mx={0.5}>
          <DroppableContainer id='Unclassified'>
            <Grid container direction='row' p={0.5} sx={{ overflowX: 'hidden' }}>
              {props.sampleGroups[0].samples.map((sample) => (
                <Grid item key={sample.id} xl={2}>
                  <DraggableItem id={sample.id}>{sample.name}</DraggableItem>
                </Grid>
              ))}
            </Grid>
          </DroppableContainer>
        </Box>
        <Grid container direction='row' spacing={2} mt={0.5} alignItems='center' justifyContent='center' height={0.5}>
          {props.sampleGroups.map(
            (group, index) =>
              index !== 0 && (
                <Grid item key={index} lg={2}>
                  <SampleClassCard
                    key={index}
                    id={group.name}
                    title={group.name}
                    samples={group.samples}
                    onDelete={() => handleMoveAllSamplesToUnclassified(group.name)}
                  />
                </Grid>
              )
          )}
          {props.sampleGroups.length <= 6 ? (
            <Grid item>
              <Button color='primary' startIcon={iconsObj.ADD} onClick={() => setOpenAddSamplesGroupDialog(true)}>
                Add Group
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <DragOverlay>{activeId ? <DraggableItem id={activeId}>{activeId && findSampleById(activeId)}</DraggableItem> : null}</DragOverlay>
      </DndContext>
      <AddSamplesGroupDialog
        open={openAddSamplesGroupDialog}
        setOpen={setOpenAddSamplesGroupDialog}
        setSampleGroupName={setSampleGroupName}
        sampleGroups={props.sampleGroups}
      />
    </React.Fragment>
  )
}

export default TargetDataBase
