import React from 'react'
import { Controller } from 'react-hook-form'
import TextField, { TextFieldProps } from '@mui/material/TextField'

import FormProps from './FormProps'

type FormInputTextProps = FormProps &
  TextFieldProps & {
    minLength?: number
    validator?: (val: string) => string | boolean
  }

const FormInputText: React.FC<FormInputTextProps> = ({ name, control, label, type = 'text', required = false, minLength, validator, ...props }) => {
  // define validation rules
  const ruleRequired = required ? { required: 'Field is required!' } : {}
  const ruleMin = minLength !== undefined ? { minLength: { value: minLength, message: `Length should be at least ${minLength}!` } } : {}
  const ruleValidator = validator ? { validate: validator } : {}

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...ruleRequired, ...ruleMin, ...ruleValidator }}
      defaultValue=''
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          {...props}
          helperText={error ? error.message : null}
          size='small'
          error={!!error}
          onChange={onChange}
          value={value || ''}
          fullWidth
          variant='outlined'
          type={type}
          required={required}
          label={label}
        />
      )}
    />
  )
}

export default FormInputText
