import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'

import { iconsObj } from '../../icons/Icons'
import { useAuth } from '../../context/AuthContext'
import SubmitButton from '../buttons/SubmitButton'
import FormInputComboBox from '../forms/FormInputComboBox'
import { Analysis, EfoDisease } from '../../pages/AnalysesManager'
import { Option } from '../forms/FormProps'
import FormInputText from '../forms/FormInputText'
import { TargetOrBaseData } from '../../pages/TargetDataManager'
import { enqueueSnackbar } from 'notistack'
import { AnalysisType } from '../../enums/AnalysisManager'
import FormInputRadioGroup from '../forms/FormInputRadioGroup'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  reloadTable: number
  setReloadTable: (reloadTable: number) => void
}

type FormInput = {
  name: string
  baseData: { id: string; label: string }
  targetData: { id: string; label: string }
  rootEfoDisease: { id: string; label: string }
  analysisType: { value: string; label: string }
}

const NewAnalysisDialog: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const [efoDiseases, setEfoDiseases] = useState<Option[]>([])
  const [targetData, setTargetData] = useState<Option[]>([])
  const [baseData, setBaseData] = useState<Option[]>([])

  const [rootEfoDisease, setRootEfoDisease] = useState<string>('')

  const { handleSubmit, reset, control, watch } = useForm<FormInput>()

  const { backendRequest, logout } = useAuth()

  const selectedRootEfoDisease = watch('rootEfoDisease')

  useEffect(() => {
    if (props.open) {
      const getData = async () => {
        const responseEfoDiseases = await backendRequest({
          method: 'GET',
          endpoint: `/api/disease/full-text-search?disease=${rootEfoDisease}`,

          requiresAuth: true,
        })

        const efoDiseasesData = responseEfoDiseases.data as EfoDisease[] | undefined

        return { status: responseEfoDiseases.status, data: efoDiseasesData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            setEfoDiseases(response.data.map((efoDisease) => ({ id: efoDisease.id, label: efoDisease.disease })))
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
    }
  }, [rootEfoDisease, props.open])

  useEffect(() => {
    if (selectedRootEfoDisease) {
      const getData = async () => {
        const responseTargetData = await backendRequest({
          method: 'GET',
          endpoint: `/api/target-data-manager/disease/${selectedRootEfoDisease.id}`,
          requiresAuth: true,
        })

        const responseBaseData = await backendRequest({
          method: 'GET',
          endpoint: `/api/base-data-manager/disease/${selectedRootEfoDisease.id}`,
          requiresAuth: true,
        })

        return { responseTargetData: responseTargetData, responseBaseData: responseBaseData }
      }
      getData()
        .then(({ responseTargetData, responseBaseData }) => {
          const targetData = responseTargetData.data as TargetOrBaseData[] | undefined
          const baseData = responseBaseData.data as TargetOrBaseData[] | undefined

          if (responseTargetData.status === 200 && targetData) {
            setTargetData(targetData.map((item) => ({ id: item.id, label: item.name })))
          } else if (responseTargetData.status === 401) {
            logout()
          }

          if (responseBaseData.status === 200 && baseData) {
            setBaseData(baseData.map((item) => ({ id: item.id, label: item.name })))
          } else if (responseBaseData.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
    }
  }, [selectedRootEfoDisease])

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    setLoading(true)

    const getData = async () => {
      const responseAnalysesManager = await backendRequest({
        method: 'POST',
        endpoint: '/api/analyses-manager',
        body: {
          name: data.name,
          analysis_type: data.analysisType,
          base_data_manager_id: data.baseData.id,
          target_data_manager_id: data.targetData.id,
          root_efo_disease_id: data.rootEfoDisease.id,
          highlighted_patient_id: null,
          p_value_threshold_id: null,
        },
        requiresAuth: true,
      })

      const analysesData = responseAnalysesManager.data as Analysis | undefined

      return { status: responseAnalysesManager.status, data: analysesData }
    }
    getData()
      .then(({ status, data }) => {
        if (status === 200 && data) {
          enqueueSnackbar(`Analysis with ID ${data.id} was successfully created`, { variant: 'success' })
          props.setReloadTable(props.reloadTable + 1)
          handleClose()
        } else if (status === 401) {
          logout()
        } else {
          enqueueSnackbar(`Error in creating analysis: ${data}`, { variant: 'error' })
        }
      })
      .catch((reason) => {
        enqueueSnackbar(`Error in creating analysis: ${reason}`, { variant: 'error' })
      })
      .finally(() => setLoading(false))
  }

  const handleClose = () => {
    props.setOpen(false)
    setEfoDiseases([])
    setRootEfoDisease('')
    reset()
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={props.open} onClose={handleClose} aria-labelledby='new-analysis-dialog' aria-describedby='new-analysis-dialog'>
      <DialogTitle>
        <Stack direction='row' spacing={1}>
          <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
            {iconsObj.ANALYSES}
          </Paper>
          <Typography variant='h5'> New Analysis</Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {iconsObj.CLOSE}
      </IconButton>
      <DialogContent>
        <Stack spacing={4} px={1} sx={{ width: '100%' }}>
          <FormInputText required id='new-analysis-name' label={'Name'} name='name' control={control} />
          <FormInputComboBox
            required
            id='new-analysis-root-efo-disease'
            label='Root EFO Disease'
            name='rootEfoDisease'
            options={efoDiseases}
            control={control}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                setRootEfoDisease(value)
              }
            }}
          />
          <FormInputComboBox
            required
            id='new-analysis-target-data'
            label='Target Data'
            name='targetData'
            options={targetData}
            control={control}
            disabled={!selectedRootEfoDisease}
          />
          <FormInputComboBox
            required
            id='new-analysis-base-data'
            label='Base Data'
            name='baseData'
            options={baseData}
            control={control}
            disabled={!selectedRootEfoDisease}
          />
          <FormInputRadioGroup
            required
            id='new-analysis-type'
            label='Analysis Type'
            name='analysisType'
            control={control}
            defaultValue={AnalysisType.general}
            options={[
              { value: AnalysisType.general, id: 'general', label: 'General' },
              { value: AnalysisType.pathway_based, id: 'pathway-based', label: 'Pathway based' },
              { value: AnalysisType.process_based, id: 'process-based', label: 'Process based' },
            ]}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mx: 3, mb: 2, mt: 3, justifyContent: 'center' }}>
        <SubmitButton id='create-new-analysis-btn' text='Submit' fullWidth onClick={handleSubmit(onSubmit)} loading={loading} startIcon={iconsObj.SUBMIT} />
      </DialogActions>
    </Dialog>
  )
}
export default NewAnalysisDialog
