import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  isLoading: boolean
}

const SimpleBackdrop: React.FC<Props> = ({ isLoading }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={isLoading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default SimpleBackdrop
