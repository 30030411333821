import React, { useEffect } from 'react'

import { enqueueSnackbar } from 'notistack'

import NewBaseDataSkeleton from '../NewBaseDataSkeleton'
import { useAuth } from '../../context/AuthContext'
import MultiSelectCheckboxTreeView, { FolderNode } from '../MultiSelectCheckboxTreeView'

type Props = {
  subDiseases: FolderNode
  setSubDiseases: (subDiseases: FolderNode) => void
  selectedRootEfoDiseaseId: number
  selectedSubDiseaseIds: number[]
  setSelectedSubDiseaseIds: (selectedSubDiseaseIds: number[]) => void
  selectedSubDiseaseDbIds: number[]
  setSelectedSubDiseaseDbIds: (selectedSubDiseaseDbIds: number[]) => void
  setHasSubDiseasesChanged: (hasSubDiseasesChanged: boolean) => void
  forceTreeViewRerender?: number
}

const BaseDataSubDiseasesStep: React.FC<Props> = (props) => {
  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    const getData = async () => {
      const responseSubDiseases = await backendRequest({
        method: 'GET',
        endpoint: `/api/disease/sub-diseases/${props.selectedRootEfoDiseaseId}`,
        requiresAuth: true,
      })

      const subDiseasesData = responseSubDiseases.data as FolderNode | undefined

      return { status: responseSubDiseases.status, data: subDiseasesData }
    }
    getData()
      .then((response) => {
        if (response.status === 200 && response.data) {
          props.setSubDiseases(response.data)
        } else if (response.status === 401) {
          logout()
        }
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
  }, [])

  return (
    <NewBaseDataSkeleton
      title='Choose Sub Diseases'
      children={
        <MultiSelectCheckboxTreeView
          data={props.subDiseases}
          selectedIds={props.selectedSubDiseaseIds}
          setSelectedIds={props.setSelectedSubDiseaseIds}
          selectedDbIds={props.selectedSubDiseaseDbIds}
          setSelectedDbIds={props.setSelectedSubDiseaseDbIds}
          setHasChanged={props.setHasSubDiseasesChanged}
          forceRerender={props.forceTreeViewRerender}
        />
      }
    />
  )
}

export default BaseDataSubDiseasesStep
