import React, { useEffect, useMemo, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

import { useAuth } from '../../context/AuthContext'
import { EfoDisease } from '../../pages/AnalysesManager'
import { ExtendedPopulation, Population } from '../new-base-data-stepper/BaseDataPopulationStep'
import { FolderNode, dbIdsToIds } from '../MultiSelectCheckboxTreeView'
import { iconsObj } from '../../icons/Icons'
import HorizontalLinearStepper from '../HorizontalLinearStepper'
import BaseDataGeneralInfoStep from '../new-base-data-stepper/BaseDataGeneralInfoStep'
import BaseDataSubDiseasesStep from '../new-base-data-stepper/BaseDataSubDiseasesStep'
import BaseDataPopulationStep from '../new-base-data-stepper/BaseDataPopulationStep'
import { SubmitHandler, useForm } from 'react-hook-form'
import BaseDataStudiesStep, { Study } from '../new-base-data-stepper/BaseDataStudiesStep'
import { GeneralInfoFormInput, PopulationFormInput, getAllSubDiseasesWithSelection } from './NewBaseDataDialog'
import SimpleBackdrop from '../SimpleBackDrop'
import { AxiosError } from 'axios'

type Props = {
  baseDataId: number
  open: boolean
  setOpen: (open: boolean) => void
  reloadTable: number
  setReloadTable: (reloadTable: number) => void
}

type BaseDataResponse = {
  id: number
  name: string
  description: string
  root_efo_disease: EfoDisease
  population: ExtendedPopulationResponse[]
  studies: ExtendedStudy[]
  efo_diseases: ExtendedEfoDisease[]
}

type ExtendedPopulationResponse = {
  gwas_population: Population
  is_selected: boolean
}

type ExtendedStudy = {
  gwas_study: Study
  is_selected: boolean
}

type ExtendedEfoDisease = {
  efo_disease: EfoDisease
  is_selected: boolean
}

const BaseDataDialog: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [baseData, setBaseData] = useState<BaseDataResponse>({} as BaseDataResponse)

  const [selectedRootEfoDiseaseId, setSelectedRootEfoDiseaseId] = useState<number>(0)

  const [subDiseases, setSubDiseases] = useState<FolderNode>({} as FolderNode)
  const [selectedSubDiseaseDbIds, setSelectedSubDiseaseDbIds] = useState<number[]>([])
  const [selectedSubDiseaseIds, setSelectedSubDiseaseIds] = useState<number[]>([])
  const [hasSubDiseasesChanged, setHasSubDiseasesChanged] = useState<boolean>(false)
  const [forceTreeViewRerender, setForceTreeViewRerender] = useState<number>(0)

  const [population, setPopulation] = useState<ExtendedPopulation[]>([])
  const [hasPopulationChanged, setHasPopulationChanged] = useState<boolean>(false)

  const [studies, setStudies] = useState<Study[]>([])
  const [selectedStudiesIds, setSelectedStudiesIds] = useState<number[]>([])
  const [hasStudiesChanged, setHasStudiesChanged] = useState<boolean>(false)

  const [activeStep, setActiveStep] = useState<number>(0)

  const { backendRequest, logout } = useAuth()

  const {
    control: controlGeneralInfo,
    reset: resetGeneralInfo,
    handleSubmit: handleSubmitGeneralInfo,
    setValue: setValueGeneralInfo,
    watch: watchGeneralInfo,
  } = useForm<GeneralInfoFormInput>()

  const selectedRootEfoDisease = watchGeneralInfo('rootEfoDisease')
  const selectedName = watchGeneralInfo('name')
  const selectedDescription = watchGeneralInfo('description')

  const {
    unregister: unregisterPopulation,
    control: controlPopulation,
    reset: resetPopulation,
    handleSubmit: handleSubmitPopulation,
  } = useForm<PopulationFormInput>()

  useEffect(() => {
    if (props.open === true) {
      setIsLoading(true)
      const getData = async () => {
        const responseBaseData = await backendRequest({
          method: 'GET',
          endpoint: `/api/base-data-manager/${props.baseDataId}`,
          requiresAuth: true,
        })

        const baseData = responseBaseData.data as BaseDataResponse | undefined

        return { status: responseBaseData.status, data: baseData }
      }
      getData()
        .then((response) => {
          if (response.status === 200 && response.data) {
            setBaseData(response.data)
            setValueGeneralInfo('name', response.data.name)
            setValueGeneralInfo('description', response.data.description)
            setValueGeneralInfo('rootEfoDisease', { label: response.data.root_efo_disease.disease, id: response.data.root_efo_disease.id })

            setSelectedRootEfoDiseaseId(response.data.root_efo_disease.id)
            setSelectedSubDiseaseDbIds(response.data.efo_diseases.filter((disease) => disease.is_selected == true).map((disease) => disease.efo_disease.id))

            setPopulation(response.data.population.map((pop) => ({ ...pop.gwas_population, isSelected: pop.is_selected })))
            const defaultValues = response.data.population?.reduce(
              (acc, { gwas_population, is_selected }) => ({
                ...acc,
                [gwas_population.population]: is_selected,
              }),
              {}
            )
            resetPopulation(defaultValues)

            setStudies(response.data.studies.map((study) => study.gwas_study))
            setSelectedStudiesIds(response.data.studies.filter((study) => study.is_selected == true).map((study) => study.gwas_study.id))
          } else if (response.status === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [props.open])

  useEffect(() => {
    if (activeStep === 1 && selectedSubDiseaseDbIds.length > 0 && selectedSubDiseaseIds.length === 0) {
      const initialIds = dbIdsToIds(subDiseases, selectedSubDiseaseDbIds)
      setSelectedSubDiseaseIds(initialIds)
      setForceTreeViewRerender((forceTreeViewRerender) => forceTreeViewRerender + 1)
    }
  }, [subDiseases])

  useEffect(() => {
    if (props.open && activeStep === 3) {
      const hasStudiesChanged =
        JSON.stringify(selectedStudiesIds.sort()) !==
        (baseData.studies &&
          JSON.stringify(
            baseData.studies
              .filter((study) => study.is_selected === true)
              .map((study) => study.gwas_study.id)
              .sort()
          ))
      setHasStudiesChanged(hasStudiesChanged)
    }
  }, [selectedStudiesIds])

  const hasDataChanged = useMemo(() => {
    const hasChanges =
      !!baseData &&
      (selectedName !== baseData.name || selectedDescription !== baseData.description || hasSubDiseasesChanged || hasPopulationChanged || hasStudiesChanged)
    return hasChanges
  }, [selectedName, selectedDescription, hasSubDiseasesChanged, selectedStudiesIds, hasStudiesChanged])

  const handleClose = () => {
    props.setOpen(false)

    resetGeneralInfo()
    resetPopulation()

    setSelectedRootEfoDiseaseId(0)

    setSelectedSubDiseaseDbIds([])
    setSelectedSubDiseaseIds([])
    setSubDiseases({} as FolderNode)

    setPopulation([])

    setStudies([])
    setSelectedStudiesIds([])
    setActiveStep(0)
  }

  const updateBaseData = async () => {
    setIsLoading(true)

    let responseMessage = ''

    if (selectedName !== baseData.name) {
      try {
        await backendRequest({
          method: 'PUT',
          endpoint: `/api/base-data-manager/${props.baseDataId}/name`,
          requiresAuth: true,
          params: { name: selectedName },
        })
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
      }
    }

    if (selectedDescription !== baseData.description) {
      try {
        await backendRequest({
          method: 'PUT',
          endpoint: `/api/base-data-manager/${props.baseDataId}/description`,
          requiresAuth: true,
          params: { description: selectedDescription },
        })
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
      }
    }

    if (hasPopulationChanged || hasSubDiseasesChanged || hasStudiesChanged) {
      const efoSubDiseases = getAllSubDiseasesWithSelection(subDiseases, selectedSubDiseaseDbIds)

      try {
        await backendRequest({
          method: 'PUT',
          endpoint: `/api/base-data-manager/${props.baseDataId}/assignments`,
          requiresAuth: true,
          body: {
            population: population.map((pop) => ({ id: pop.id, is_selected: pop.isSelected })),
            studies: studies.map((study) => ({ id: study.id, is_selected: selectedStudiesIds.includes(study.id) })),
            efo_diseases: efoSubDiseases.map((subDisease) => ({ id: subDisease.db_id, is_selected: subDisease.is_selected })),
          },
        })
      } catch (error) {
        const err = error as AxiosError
        responseMessage += err.response?.statusText
      }
    }

    if (responseMessage === '') {
      props.setReloadTable(props.reloadTable + 1)
      enqueueSnackbar(`Base data with ID ${props.baseDataId} was successfully saved.`, { variant: 'success' })
      handleClose()
    } else {
      enqueueSnackbar(`An error occurred while saving the target data. ${responseMessage}`, { variant: 'error' })
    }

    setIsLoading(false)
  }

  const onGeneralInfoSubmit: SubmitHandler<GeneralInfoFormInput> = async (data) => {
    setSelectedRootEfoDiseaseId(data.rootEfoDisease.id)
    if (baseData.root_efo_disease.id !== data.rootEfoDisease.id) {
      setSelectedSubDiseaseIds([])
      setSelectedSubDiseaseDbIds([])
    }
    setActiveStep(1)
  }

  const onSubDiseasesSubmit = () => {
    if (selectedSubDiseaseDbIds.length === 0) {
      enqueueSnackbar('Please select at least one sub-disease', { variant: 'error' })
      return
    }
    setActiveStep(2)
  }

  const onPopulationSubmit: SubmitHandler<PopulationFormInput> = async (data) => {
    const selectedPopulation: ExtendedPopulation[] = []

    // Find if at least one population is selected
    if (Object.values(data).every((isSelected) => !isSelected)) {
      enqueueSnackbar('Please select at least one population', { variant: 'error' })
      return
    }

    Object.entries(data).forEach(([populationName, isSelected]) => {
      const pop = population.find((pop) => pop.population === populationName)
      if (pop) {
        selectedPopulation.push({ id: pop.id, population: pop.population, isSelected: isSelected })
      }
    })

    // Find out if there are any changes in the selected population
    const hasPopulationChanged = selectedPopulation.some((pop) => pop.isSelected !== population.find((p) => p.id === pop.id)?.isSelected)
    setHasPopulationChanged(hasPopulationChanged)
    if (hasPopulationChanged) {
      setSelectedStudiesIds([])
    }

    setPopulation(selectedPopulation)
    setActiveStep(3)
  }

  const onStudiesSubmit = () => {
    if (selectedStudiesIds.length === 0) {
      enqueueSnackbar('Please select at least one study', { variant: 'error' })
      return
    }
    updateBaseData()
  }

  const customHandleNext = () => {
    if (activeStep === 0) {
      handleSubmitGeneralInfo(onGeneralInfoSubmit)()
    } else if (activeStep === 1) {
      onSubDiseasesSubmit()
    } else if (activeStep === 2) {
      handleSubmitPopulation(onPopulationSubmit)()
    } else if (activeStep === 3) {
      onStudiesSubmit()
    }
  }

  const steps = [
    {
      title: 'Select General Information',
      id: 'select-general-information',
      component: (
        <BaseDataGeneralInfoStep
          initialRootEfoDisease={selectedRootEfoDisease}
          efoDiseases={[]}
          setEfoDiseases={() => {}}
          control={controlGeneralInfo}
          setValue={setValueGeneralInfo}
          disabledRootEfoDisease={true}
        />
      ),
    },
    {
      title: 'Select Sub-diseases',
      id: 'select-sub-diseases',
      component: (
        <BaseDataSubDiseasesStep
          subDiseases={subDiseases}
          setSubDiseases={setSubDiseases}
          selectedRootEfoDiseaseId={selectedRootEfoDiseaseId}
          selectedSubDiseaseIds={selectedSubDiseaseIds}
          setSelectedSubDiseaseIds={setSelectedSubDiseaseIds}
          selectedSubDiseaseDbIds={selectedSubDiseaseDbIds}
          setSelectedSubDiseaseDbIds={setSelectedSubDiseaseDbIds}
          setHasSubDiseasesChanged={setHasSubDiseasesChanged}
          forceTreeViewRerender={forceTreeViewRerender}
        />
      ),
    },
    {
      title: 'Select Population',
      id: 'select-population',
      component: (
        <BaseDataPopulationStep
          population={population}
          setPopulation={setPopulation}
          subDiseaseIds={selectedSubDiseaseIds}
          control={controlPopulation}
          reset={resetPopulation}
          hasSubDiseasesChanged={hasSubDiseasesChanged}
          setHasSubDiseasesChanged={setHasSubDiseasesChanged}
          setHasPopulationChanged={setHasPopulationChanged}
          unregister={unregisterPopulation}
        />
      ),
    },
    {
      title: 'Select Studies',
      id: 'select-studies',
      component: (
        <BaseDataStudiesStep
          studies={studies}
          setStudies={setStudies}
          selectedSubDiseaseIds={selectedSubDiseaseDbIds}
          selectedPopulationIds={population.filter((pop) => pop.isSelected).map((pop) => pop.id)}
          setSelectedStudiesIds={setSelectedStudiesIds}
          selectedStudiesIds={selectedStudiesIds}
          hasPopulationChanged={hasPopulationChanged}
          setHasPopulationChanged={setHasPopulationChanged}
        />
      ),
    },
  ]

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={false}
        scroll='paper'
        open={props.open}
        onClose={handleClose}
        aria-labelledby='new-base-data-dialog'
        aria-describedby='new-base-data-dialog'
      >
        <DialogTitle>
          <Stack direction='row' spacing={1}>
            <Paper elevation={0} sx={{ backgroundColor: 'inherit', pt: 0.5 }}>
              {iconsObj.BASE_DATA}
            </Paper>
            <Typography variant='h5'> {baseData.name}</Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {iconsObj.CLOSE}
        </IconButton>
        <DialogContent sx={{ height: '90vh', overflow: 'auto' }}>
          <HorizontalLinearStepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            customHandleNext={customHandleNext}
            lastStepButtonName='Save'
            disabledLastStepButton={!hasDataChanged}
          />
          {steps[activeStep].component}
        </DialogContent>
      </Dialog>
      <SimpleBackdrop isLoading={isLoading} />
    </React.Fragment>
  )
}

export default BaseDataDialog
