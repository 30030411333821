import React, { useEffect, useState } from 'react'
import NewBaseDataSkeleton from '../NewBaseDataSkeleton'
import { Option } from '../forms/FormProps'
import FormInputComboBox from '../forms/FormInputComboBox'
import { Stack } from '@mui/material'
import FormInputText from '../forms/FormInputText'
import { Control, UseFormSetValue } from 'react-hook-form'
import { GeneralInfoFormInput, RootEfoDiseaseOption } from '../dialogs/NewBaseDataDialog'
import { enqueueSnackbar } from 'notistack'
import { EfoDisease } from '../../pages/AnalysesManager'
import { useAuth } from '../../context/AuthContext'

type Props = {
  initialRootEfoDisease: RootEfoDiseaseOption | undefined
  efoDiseases: Option[]
  setEfoDiseases: (efoDiseases: Option[]) => void
  control: Control<GeneralInfoFormInput>
  setValue: UseFormSetValue<GeneralInfoFormInput>
  disabledRootEfoDisease?: boolean
}

const BaseDataGeneralInfoStep: React.FC<Props> = ({ disabledRootEfoDisease = false, ...props }) => {
  const [rootEfoDiseaseUserInput, setRootEfoDiseaseUserInput] = useState<string>(props.initialRootEfoDisease ? props.initialRootEfoDisease.label : '')
  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    const getData = async () => {
      const responseEfoDiseases = await backendRequest({
        method: 'GET',
        endpoint: `/api/disease/full-text-search?disease=${rootEfoDiseaseUserInput}`,
        requiresAuth: true,
      })

      const efoDiseasesData = responseEfoDiseases.data as EfoDisease[] | undefined

      return { status: responseEfoDiseases.status, data: efoDiseasesData }
    }
    getData()
      .then((response) => {
        if (response.status === 200 && response.data) {
          props.setEfoDiseases(response.data.map((efoDisease) => ({ id: efoDisease.id, label: efoDisease.disease })))
        } else if (response.status === 401) {
          logout()
        }
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
  }, [rootEfoDiseaseUserInput, props.initialRootEfoDisease])

  return (
    <NewBaseDataSkeleton
      title='Choose General Information'
      children={
        <Stack spacing={3}>
          <FormInputText required id='new-base-data-name' label='Name' name='name' control={props.control} />
          <FormInputText id='new-target-data-description' label='Description' name='description' control={props.control} />
          <FormInputComboBox
            required
            id='new-base-data-root-efo-disease'
            label='Root EFO Disease'
            name='rootEfoDisease'
            disabled={disabledRootEfoDisease}
            options={props.efoDiseases}
            control={props.control}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                setRootEfoDiseaseUserInput(value)
              }
            }}
          />
        </Stack>
      }
    />
  )
}

export default BaseDataGeneralInfoStep
