import { Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'

type Props = {
  children: React.ReactNode
  title: string
}

const NewBaseDataSkeleton: React.FC<Props> = (props) => {
  return (
    <Grid container direction='row' justifyContent='space-evenly' alignItems='stretch' mt={3} height={'70vh'}>
      <Grid item xs={8} px={2}>
        <Stack spacing={2}>
          <Typography variant='h6'>{props.title}</Typography>
          {props.children}
        </Stack>
      </Grid>
      <Divider orientation='vertical' flexItem />
      <Grid item xs={3} px={2}>
        <Typography variant='h6'>Number of Selected SNPs</Typography>
      </Grid>
    </Grid>
  )
}

export default NewBaseDataSkeleton
