import React, { memo, useMemo, useState } from 'react'

import { Link } from 'react-router-dom'

import { styled, Theme, CSSObject } from '@mui/material/styles'
import { Box, CssBaseline, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'

import { iconsObj } from '../icons/Icons'
import { useAuth } from '../context/AuthContext'
import LoginDialog from './dialogs/LoginDialog'
import { ThemeProps } from '../App'
import { Brightness7, Brightness4 } from '@mui/icons-material'

type Props = {
  changeMode: () => void
  theme: ThemeProps
}

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

type Page = {
  title: string
  key: string
  href: string
  icon: React.ReactNode
}

type NavItemProps = {
  page: Page
  open: boolean
  id: string
}

const NavItem: React.FC<NavItemProps> = memo((props) => (
  <ListItem disablePadding sx={{ display: 'block' }}>
    <ListItemButton
      component={Link}
      to={props.page.href}
      id={props.id}
      sx={{
        justifyContent: props.open ? 'initial' : 'center',
        px: 2.5,
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: props.open ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        {props.page.icon}
      </ListItemIcon>
      <ListItemText primary={props.page.title} sx={{ opacity: props.open ? 1 : 0 }} />
    </ListItemButton>
  </ListItem>
))

const NavBar: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false)

  const { isUserAuthenticated, logout } = useAuth()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogout = () => {
    handleClose()
    logout()
  }

  const pages = useMemo((): Page[] => {
    const items = [{ title: 'Home', key: 'home', icon: iconsObj.HOME, href: '/' }]

    if (isUserAuthenticated) {
      items.push({ title: 'Target Data Manager', key: 'target-data-manager', icon: iconsObj.TARGET_DATA, href: '/target-data-manager' })
      items.push({ title: 'Base Data Manager', key: 'base-data-manager', icon: iconsObj.BASE_DATA, href: '/base-data-manager' })
      items.push({ title: 'Analyses Manager', key: 'analyses-manager', icon: iconsObj.ANALYSES, href: '/analyses-manager' })
    }
    return items
  }, [isUserAuthenticated])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant='permanent' open={open} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <img
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt='Logo'
              style={{ width: 'auto', height: 'auto', maxWidth: '40px', maxHeight: '35px', marginLeft: 5 }}
            />
            <Typography variant='h6' noWrap marginLeft={2}>
              PolyRisk
            </Typography>
          </DrawerHeader>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
              <List>
                {pages.map((page) => (
                  <NavItem key={page.key} page={page} id={`${page.key}-btn`} open={open} />
                ))}
              </List>
            </Box>
            <List>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  onClick={() => props.changeMode()}
                  color='inherit'
                >
                  <ListItemIcon
                    aria-label='theme'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {props.theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
                  </ListItemIcon>
                  <ListItemText primary={'Theme'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                {isUserAuthenticated ? (
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={handleMenu}
                  >
                    <ListItemIcon
                      aria-label='account of current user'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      id='account-btn'
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {iconsObj.ACCOUNT}
                    </ListItemIcon>
                    <ListItemText primary={'Account'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => setOpenLoginModal(true)}
                  >
                    <ListItemIcon
                      aria-label='account of current user'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      id='login-btn'
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {iconsObj.LOGIN}
                    </ListItemIcon>
                    <ListItemText primary={'Log In'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                )}
              </ListItem>
            </List>
          </Box>
          {isUserAuthenticated && (
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem id='logout-btn' onClick={onLogout}>Log Out</MenuItem>
              <MenuItem onClick={handleClose} disabled>
                Change Password
              </MenuItem>
            </Menu>
          )}
        </Drawer>
        <Box component='main' sx={{ flexGrow: 1 }}>
          <DrawerHeader />
        </Box>
      </Box>
      <LoginDialog open={openLoginModal} setOpen={setOpenLoginModal} />
    </React.Fragment>
  )
}

export default NavBar
