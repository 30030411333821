import React, { useEffect, useMemo, useState } from 'react'
import TablePageSkeleton from '../components/TablePageSkeleton'
import PageHeading from '../components/PageHeading'
import { iconsObj } from '../icons/Icons'
import { Button, ButtonGroup } from '@mui/material'
import CustomDataGrid from '../components/CustomDataGrid'
import { DataGridProps, GridRenderCellParams } from '@mui/x-data-grid'
import { useAuth } from '../context/AuthContext'
import TableIconButton from '../components/buttons/TableIconButton'
import BackendRequestDialog from '../components/dialogs/BackendRequestDialog'
import { EfoDisease } from './AnalysesManager'
import NewTargetDataDialog from '../components/dialogs/NewTargetDataDialog'
import TargetDataDialog from '../components/dialogs/TargetDataDialog'
import { enqueueSnackbar } from 'notistack'

export type TargetOrBaseData = {
  id: number
  name: string
  description: string
  root_efo_disease: EfoDisease
}

type ExtendedTargetData = TargetOrBaseData & {
  actions: React.ReactNode
}

const TargetDataManager: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [extendedTargetData, setExtendedTargetData] = useState<ExtendedTargetData[]>([])

  const [selectedTargetDatatId, setSelectedTargetDatatId] = useState<number>(0)

  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false)

  const [showTargetDataDialog, setShowTargetDataDialog] = useState<boolean>(false)
  const [showAddTargetDataDialog, setShowAddTargetDataDialog] = useState<boolean>(false)

  const [reloadTable, setReloadTable] = useState<number>(0)

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    setIsLoading(true)

    const getData = async () => {
      const responseTargetData = await backendRequest({
        method: 'GET',
        endpoint: '/api/target-data-manager/all',
        requiresAuth: true,
      })

      const targetData = responseTargetData.data as TargetOrBaseData[] | undefined

      return { status: responseTargetData.status, data: targetData }
    }
    getData()
      .then((responseTargetData) => {
        if (responseTargetData.status === 200 && responseTargetData.data) {
          const extendedTargetData: ExtendedTargetData[] = responseTargetData.data.map((targetData: TargetOrBaseData) => {
            const actions = (
              <ButtonGroup>
                <TableIconButton
                  icon={iconsObj.OPEN}
                  size='small'
                  tooltipText='Open Target Data'
                  id='open_target_data_btn'
                  onClick={() => {
                    setSelectedTargetDatatId(targetData.id)
                    setShowTargetDataDialog(true)
                  }}
                />
                <TableIconButton
                  icon={iconsObj.DELETE}
                  size='small'
                  tooltipText='Delete Target Data'
                  id='delete_target_data_btn'
                  onClick={() => {
                    setSelectedTargetDatatId(targetData.id)
                    setShowRemoveDialog(true)
                  }}
                />
              </ButtonGroup>
            )
            return { ...targetData, actions }
          })

          setExtendedTargetData(extendedTargetData)
        } else if (responseTargetData.status === 401) {
          logout()
        }
        setIsLoading(false)
      })
      .catch((error) => {
        enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
      })
  }, [reloadTable])

  const columnSpec = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        editable: false,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'name',
        headerName: 'Name',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'description',
        headerName: 'Description',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
      },
      {
        field: 'disease_trait',
        headerName: 'Disease / Trait',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params: GridRenderCellParams) => params.row.root_efo_disease.disease,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => params.row.actions,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TablePageSkeleton
        headerGridLeft={<PageHeading icon={iconsObj.TARGET_DATA}>Target Data Manager</PageHeading>}
        headerGridRight={
          <Button variant='outlined' startIcon={iconsObj.ADD} onClick={() => setShowAddTargetDataDialog(true)}>
            Add Target Data
          </Button>
        }
        table={<CustomDataGrid rows={extendedTargetData} columns={columnSpec as DataGridProps['columns']} isLoading={isLoading} />}
      />
      <BackendRequestDialog
        show={showRemoveDialog}
        setShow={setShowRemoveDialog}
        handleSuccess={() => {
          setReloadTable((reloadTable) => reloadTable + 1)
        }}
        endpoint={`/api/target-data-manager/${selectedTargetDatatId}`}
        requestMethod='DELETE'
        title={'Are you sure you want to remove Target Data ' + selectedTargetDatatId + '?'}
        buttonText='Remove'
        buttonColor='error'
        buttonIcon={iconsObj.DELETE}
        id='remove-target-data-dialog'
      />
      <TargetDataDialog
        targetDataId={selectedTargetDatatId}
        open={showTargetDataDialog}
        setOpen={setShowTargetDataDialog}
        reloadTable={reloadTable}
        setReloadTable={setReloadTable}
      />
      <NewTargetDataDialog open={showAddTargetDataDialog} setOpen={setShowAddTargetDataDialog} reloadTable={reloadTable} setReloadTable={setReloadTable} />
    </React.Fragment>
  )
}

export default TargetDataManager
